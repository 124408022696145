<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="mt-md-5">
          <p
            style="background-color: #EDBD43; padding: 0.5em; border-radius: 4px; font-size: 0.8em;"
          >
            <strong>
              Mulheres garimpeiras e pescadoras: quando haverá reconhecimento?
            </strong>
          </p>
        </div>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p class="pa-3 mb-2 my-auto" style="background-color: #F3F3F3;">
                  “A gente gostava muito de panhar peixe. Agora, como deu a
                  barragem, não tem jeito mais. Eu pescava pro sustento. Se eu
                  for falar, eu criei meus filhos tudo com peixe. Pegava traíra,
                  bagre, acará, lambari. Passava fubá, fritava e comia aquele
                  peixe torradinho. Hoje, não dá pra comer por conta da lama.
                  Essa falta do peixe aperta no fim do mês, porque a gente não
                  tá aguentando comprar carne (Rosária Conceição da Silva,
                  atingida, 2019).”
                </p>
                <p>
                  O rompimento da barragem de Fundão afetou histórias de vidas,
                  identidades, profissões e sonhos que foram soterrados na lama.
                  Se as mulheres têm o direito de sonhar, esse direito está
                  suspenso desde o dia em que a lama foi derramada em todo o rio
                  Gualaxo do Norte e todo o Rio Doce. Desde esse dia, milhares
                  de mulheres perderam seu sustento que vinha diretamente do
                  rio: a pesca e o ofício de garimpo artesanal. Trataremos aqui
                  da situação das mulheres garimpeiras e das mulheres
                  pescadoras, que partilham da luta por reconhecimento e
                  apresentam barreiras específicas quando se trata das
                  indenizações pelos danos.
                </p>
                <p>
                  Cumpre lembrar que o garimpo, a agricultura familiar e a pesca
                  compõem os modos de vida que produzem o território tradicional
                  habitado pelas comunidades marianenses. “Pescadora tem o
                  ofício marcado no corpo, pele preta na maioria das vezes com a
                  lama do mangue, que se doura com o sol de todos os dias, por
                  longos anos” (Eliane BALKE, atingida do Rio Doce, 2020).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_15.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Daniela Felix. Jornal A Sirene, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Sobre os danos desde o rompimento da Barragem, uma mulher
                  garimpeira que é mãe de duas filhas, narra que não tem o mesmo
                  retorno financeiro desde o rompimento da barragem de Fundão, o
                  que lhe gera sérios transtornos psicológicos pela preocupação
                  com o sustento da família, passando a fazer uso contínuo de
                  medicamentos. A atingida conta que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “[...] minha saúde antes do rompimento estava boa, e após passou a
            ficar um pouco abalada. Quando chove, sinto medo de acontecer um
            novo rompimento. Eu trabalho no garimpo, e não tenho o mesmo retorno
            financeiro como antes. Eu tenho duas filhas e me sinto um pouco
            insegura sobre o que posso oferecer a elas. Isso me tira o sono.
            Penso muito no que pode ser feito para melhorar, todos os dias sofro
            um pouco mais. Passei a ficar mais ansiosa e agitada, sinto muita
            dificuldade para dormir. Passei a frequentar os postos de saúde mais
            vezes para pegar medicamentos. Sinto dores de cabeça quando fico
            mais agitada. Cheguei a consultar pelos problemas que me afligem, e
            preciso fazer uso contínuo de medicamentos.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_16.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Daniela Felix. Jornal A Sirene, 2017.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Junto da perda da possibilidade de praticar o
                  <span class="pink--text">garimpo artesanal</span> no rio
                  Gualaxo do Norte, ofício que herdou de seus pais, a atingida
                  perdeu também a possibilidade de usufruir do rio para a pesca
                  e para a subsistência: não o utiliza mais devido à poluição
                  tóxica de rejeitos. Atualmente, o território onde viviam está
                  marcado pela contaminação das águas e do solo pelo rejeito de
                  minério de ferro. No rio, ela pescava lambaris e utilizava dos
                  peixes e da água para cozinhar para outras pessoas do garimpo
                  e também para sua família. Uma vida que antes era pautada no
                  garimpo artesanal, na pesca e na relação com a comunidade de
                  Ponte do Gama, hoje está pautada pelas incertezas em relação
                  ao futuro, pelas dívidas e pela ausência do rio Gualaxo do
                  Norte.
                </p>
                <p>
                  Ao chegar na FNE, vê-se a desesperança nos olhos dessas
                  mulheres. Isso porque a Fundação Renova não indeniza as(os)
                  garimpeiras(os) pelos danos aos seus instrumentos de trabalho
                  e pela perda do ofício, alegando a falta de regulamentação da
                  profissão.
                </p>
                <p>
                  Quando se considera o gênero, percebem-se violações muito
                  específicas. A atingida Carolina Rodrigues conta que: “eles
                  têm descaso tanto com garimpeiros quanto com pescadores, ainda
                  mais se tratando de uma mulher. Eles não reconhecem as
                  pescadoras, mesmo quando temos provas de que pescávamos”
                  (RODRIGUES et al., 2019).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Há, ainda, as situações em que apenas o nome do homem do
                  núcleo familiar é mencionado nas propostas indenizatórias,
                  tendo por consequência o depósito integral dos valores na
                  conta bancária do homem atingido, e não da mulher atingida. A
                  Fundação Renova diz que “o valor da indenização dos bens
                  materiais perdidos na atividade de garimpo está direcionada
                  apenas ao [...] (homem garimpeiro), entretanto a Fundação
                  verificou em análise que a [...] (mulher garimpeira) também
                  exercia a atividade citada, desta forma, caso seja do
                  interesse do núcleo familiar é possível se realizar o rateio
                  dos itens”<sup>1</sup>. Percebe-se que o reconhecimento dos
                  danos da mulher é secundário. Além disso, caso deseje ter seu
                  nome contemplado na proposta, precisará esperar por tempo
                  indeterminado para que o analista faça o rateio adequado.
                  Exausta do processo de reparação que já se estende há mais de
                  seis anos, a mulher acaba por aceitar a proposta sem o seu
                  nome.
                </p>
                <p>
                  Tudo indica que há um apagamento das mulheres atingidas no
                  processo de reparação dos danos, seja por meio da estratégia
                  de elaboração de propostas que não constam seus nomes, seja
                  pela prática da Fundação Renova de centralizar os diálogos na
                  figura masculina do lar.
                </p>
                <p>
                  O posicionamento da Renova de não indenizar às famílias, pelos
                  materiais de trabalho perdidos ou destruídos pela lama, faz
                  com que as atingidas chorem nas reuniões, diante da
                  deslegitimação de suas falas e de sua profissão. Dessa forma,
                  a Fundação Renova promove a completa desqualificação das
                  vivências dessas mulheres, por meio da invisibilização e da
                  negação dos direitos no processo de reparação dos danos
                  sofridos por elas, reproduzindo violações sistemáticas e
                  reforçando a marginalização da própria identidade das mulheres
                  garimpeiras e pescadoras do Rio Gualaxo do Norte.
                </p>

                <p style="text-align: center">
                  <strong
                    >Autoras: Gabriela Câmara<sup>2</sup>, Geruza Luiza
                    Silva<sup>3</sup>, Giovana Galvão<sup>4</sup>, Karine Lemos
                    Gomes Ribeiro<sup>5</sup>, Laís Jabace Maia<sup>6</sup>,
                    Paula Zanardi<sup>7</sup></strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_17.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Daniela Felix. Jornal A Sirene, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Diálogo feito em reunião de mediação, realizada entre
              a assessoria jurídica, a família e a Fundação Renova, motivo pelo
              qual não há documentos públicos em que haja menção ao diálogo em
              razão do princípio da confidencialidade.
            </p>
            <p>
              <sup>2</sup> Advogada, bacharel em direito pela Universidade
              Federal de Viçosa; especialista em Gerenciamento de Projetos pela
              PUC Minas; coordenadora operacional da equipe de Assessoria
              Jurídica da ATI em Mariana.
            </p>
            <p>
              <sup>3</sup> Bacharela em Serviço Social pela UFOP. Assessora
              Técnica na ATI Mariana.
            </p>
            <p>
              <sup>4</sup> Bacharela em Direito pela UFRN e Mestra em Direito
              pela UFOP. Assessora jurídica na ATI Mariana.
            </p>
            <p>
              <sup>5</sup> Advogada. Bacharela e Mestra em Direito pela UFOP.
              Assessora jurídica na ATI Mariana.
            </p>
            <p>
              <sup>6</sup> Cientista Social (UFMG), mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Desde 2020 é
              Coordenadora Operacional da Assessoria Técnica aos Atingidos e
              Atingidas pelo Rompimento da Barragem do Fundão em Mariana pela
              Cáritas Brasileira Regional Minas Gerais.
            </p>
            <p>
              <sup>7</sup> Cientista Social pela UFSC, mestra em Preservação do
              Patrimônio Cultural pelo IPHAN. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <ScrollTop />
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },
        {
          text: "Mulheres Garimpeiras e Pescadoras",
          href: "conteudos_tematicos_garimpeiras",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
